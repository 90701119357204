var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.isLoading)?_c('aom-skeleton-loader'):_c('b-form',{staticClass:"mb-4"},[_c('b-card',{attrs:{"title":"Profile Details"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"profile_image",attrs:{"rules":{ required: true },"vid":"profile_image","name":"Profile Image"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"photo-input"}},[_c('div',{staticClass:"photo-wrapper"},[_c('fileUpload',{attrs:{"lang-type":"en","field":"profile_img","width":80,"height":80,"img-format":"png","lang-ext":Object.assign({}, _vm.cropperSettings)},on:{"srcFileSet":_vm.cropSuccess,"crop-success":_vm.cropSuccess},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [(!_vm.imgDataUrl)?_c('b-avatar',{attrs:{"variant":"primary","text":_vm.userInitials,"src":_vm.userProfileImage,"size":"8rem"}}):_c('img',{staticStyle:{"width":"8rem"},attrs:{"src":_vm.imgDataUrl,"alt":"profile_image"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"media-body mt-75 ml-75"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.showCropperDialog}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Upload")])],1),_c('p',{staticClass:"card-text"},[_c('small',[_vm._v("Allowed JPG, GIF, BMP or PNG. Max size of 5MB")])]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)])],1)])]}}])})],1)],1),_c('hr',{staticClass:"my-2"}),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"firstName",attrs:{"rules":{ required: true },"name":"First name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('First name'),"label-for":"first-name"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('First name'),"name":"first-name"},model:{value:(_vm.userEdit.first_name),callback:function ($$v) {_vm.$set(_vm.userEdit, "first_name", $$v)},expression:"userEdit.first_name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"lastName",attrs:{"rules":"required","name":"Last name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Last name'),"label-for":"last-name"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Last name'),"name":"last-name"},model:{value:(_vm.userEdit.last_name),callback:function ($$v) {_vm.$set(_vm.userEdit, "last_name", $$v)},expression:"userEdit.last_name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"email",attrs:{"rules":{ required: true },"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Email'),"name":"email"},model:{value:(_vm.userEdit.email),callback:function ($$v) {_vm.$set(_vm.userEdit, "email", $$v)},expression:"userEdit.email"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"phone",attrs:{"rules":"required|aom_phone","name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('aom-tel-input',{model:{value:(_vm.userEdit.phone),callback:function ($$v) {_vm.$set(_vm.userEdit, "phone", $$v)},expression:"userEdit.phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"roles"}},[_c('user-roles-select',{attrs:{"list-type":_vm.userRolesListType,"read-only":true},on:{"update":_vm.updateSelectedUserRole},model:{value:(_vm.userEdit.user_roles),callback:function ($$v) {_vm.$set(_vm.userEdit, "user_roles", $$v)},expression:"userEdit.user_roles"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{ref:"languages",attrs:{"name":"languages"}},[_c('b-form-group',{attrs:{"label":"Preferred Language","label-for":"selected-language"}},[_c('v-select',{staticStyle:{"pointer-events":"none"},attrs:{"options":_vm.languageList,"multiple":"","label":"name"},model:{value:(_vm.userEdit.preferred_language),callback:function ($$v) {_vm.$set(_vm.userEdit, "preferred_language", $$v)},expression:"userEdit.preferred_language"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Email Notifications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"aria-describedby":ariaDescribedby,"switch":""},model:{value:(_vm.emailNotifications),callback:function ($$v) {_vm.emailNotifications=$$v},expression:"emailNotifications"}})]}}])})],1)],1)],1)],1),(_vm.showIntegrationsSection)?_c('b-card',{attrs:{"title":"Integrations"}},[_c('b-row',[(_vm.isDisplayTeams)?_c('b-col',{attrs:{"sm":"4"}},[_c('validation-provider',{ref:"teams",attrs:{"rules":{ required: true },"name":"Teams"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Teams'),"label-for":"teams"}},[_c('template',{slot:"label"},[_vm._v(" Teams "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Teams Email'),expression:"'Teams Email'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-50 my-icon",attrs:{"icon":"AlertCircleIcon"}})],1),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Teams'),"name":"teams"},model:{value:(_vm.userEdit.teams),callback:function ($$v) {_vm.$set(_vm.userEdit, "teams", $$v)},expression:"userEdit.teams"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,4168656282)})],1):_vm._e(),(_vm.isDisplayZoom)?_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Zoom'),"label-for":"zoom"}},[_c('template',{slot:"label"},[_vm._v(" Zoom "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Zoom Account ID'),expression:"'Zoom Account ID'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-50 my-icon",attrs:{"icon":"AlertCircleIcon"}})],1),(!_vm.userEdit.zoom)?_c('b-link',{staticClass:"btn btn-primary btn-block",attrs:{"href":_vm.zoomOAuthLink}},[_vm._v(" Connect ")]):_c('b-button',{attrs:{"variant":"primary","block":""}},[_vm._v(" Connected ")])],2)],1):_vm._e(),(_vm.isDisplaySkype)?_c('b-col',{attrs:{"sm":"4"}},[_c('validation-provider',{ref:"skype",attrs:{"rules":"","name":"Skype ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Skype ID'),"label-for":"skype"}},[_c('template',{slot:"label"},[_vm._v(" Skype ID "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Skype ID'),expression:"'Skype ID'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-50 my-icon",attrs:{"icon":"AlertCircleIcon"}})],1),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Skype ID'),"name":"skype"},model:{value:(_vm.userEdit.skype),callback:function ($$v) {_vm.$set(_vm.userEdit, "skype", $$v)},expression:"userEdit.skype"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,2240517495)})],1):_vm._e()],1)],1):_vm._e(),_c('b-card',{attrs:{"title":"Data & Account"}},[_c('div',{staticClass:"d-flex align-items-start"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.downLoadUserPDF}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"DownloadIcon","size":"16"}}),(_vm.isFetching)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Download a copy of all my information")])],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.terminateAccountAndLogout}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"TrashIcon","size":"16"}}),_vm._v(" Terminate my account ")],1)],1)]),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.onSaveChanges}},[(_vm.isSubmitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isSubmitting)?_c('span',[_vm._v("Updating...")]):_c('span',[_vm._v("Submit")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }